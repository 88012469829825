export default {
  CLIENT_FREIGHT: 1,
  TRANSPORTER_FREIGHT: 2,
  TEO: 13,
  CLIENT_DISPATCHERS: 3,
  SOTKA_SALESMAN: 4,
  SOTKA_LOGIST: 5,
  BROKERS_TYPE: 6,
  BONUS_TYPE: 17
}
