export default {
  id: 'ID',
  deal_status_id: 'Статус ID',
  date_loading: 'Дата загрузки',
  ttn_track: 'Трек ТТН',
  transporter_date_unloading: 'Дата выгрузки',
  point_loadings: 'Город (откуда)',
  point_unloading: 'Город (куда)',
  client_name: 'Компания (З)',
  client_code: 'ЕДРПОУ (З)',
  client_legal_entity: 'Юр. лицо. (З)',
  client_delay: 'Отсрочка',
  client_cash_true: 'Фрахт. нал',
  client_cash_true_payment_form: 'Ф.О. нал',
  client_cash_false: 'Фрахт. безнал',
  client_cash_false_payment_form: 'Ф.О. безнал',
  client_dispatching: 'Дисп. кл.',
  client_debt: 'Долг (З)',
  client_debt_0: 'Просроченная',
  client_debt_30: 'до 30 дней',
  client_debt_31_90: '30-90 дней',
  client_debt_91: 'более 90 дней',
  client_transportation_number: '№ транспор.',
  docs_track: 'Трек доков',
  client_document_primary: 'Первичка',
  client_notes_for_doc: 'Памятка к докам',
  client_document_is_broken_document: 'Акты боя\\недостачи',
  manager_comment_client_docs: 'Коммент. М. к докам',
  client_document_description: 'Коммент.к получ. докам',
  client_document_date_send: 'Дата отпр. доков',
  client_document_invoice_number: '№ счета',
  client_document_invoice_date: 'Дата счета',
  client_document_number_declaration: '№ декл. отправки',
  client_get_docs_contact: 'Контакт, получение доков',
  client_get_docs_date: 'Дата получ.',
  client_document_is_order: 'Заявка (З)',
  client_date_order: 'Заявка дата (З)',
  client_paid: 'Оплачено',
  client_paid_date: 'Дата оплаты',
  client_last_paid_date: 'Дата последней оплаты (З)',
  client_expiration: 'Среднее кол-во дней просрочки (З)',
  client_expiration_date: 'Дата проср.',
  client_rnn: 'РНН (З)',
  client_rnn_blocked: 'Блок РНН',
  transporter_name: 'Компания (П)',
  transporter_code: 'ЕДРПОУ (П)',
  transporter_legal_entity: 'Юр. лицо. (П)',
  transporter_delay: 'Отсрочка',
  transporter_cash_true: 'Фрахт. нал',
  transporter_cash_true_payment_form: 'Ф.О. нал',
  transporter_cash_false: 'Фрахт. безнал',
  transporter_cash_false_payment_form: 'Ф.О. безнал',
  teo_company: 'Компания (ТЭО)',
  teo_freight: 'Фрахт (ТЭО)',
  teo_payment_form: 'Ф.О. (ТЭО)',
  teo_paid: 'Оплачено (ТЭО)',
  teo_paid_date: 'Дата оплаты (ТЭО)',
  teo_debt: 'Долг (ТЭО)',
  teo_document_invoice_number: '№ счета (ТЭО)',
  teo_document_invoice_date: 'Дата счета (ТЭО)',
  teo_rnn: 'РНН (ТЭО)',
  transporter_debt: 'Долг (П)',
  transporter_driver_name: 'ФИО водителя (П)',
  transporter_driver_phone: 'Тел. водителя',
  transporter_numbers: 'Номера машины/п.пр',
  transporter_volume: 'Объем',
  transporter_max_capacity: 'Тоннаж',
  transporter_car_brand: 'Марка',
  transporter_vehicle_types: 'Тип транспорта',
  transporter_container_number: '№ контейн.',
  transporter_marine_line: 'Линия',
  transporter_soft_credit_card: '№ карты (по софту)',
  transporter_soft_name: 'ФИО владельца карты',
  transporter_document_ttn: 'Наличие доков (ТТН, оригин.)',
  transporter_document_date_send: 'Дата получ.',
  transporter_document_number_declaration: '№ декл. получ.',
  transporter_postage: 'Затр. на получ.',
  transporter_medical_book: 'Мед.книжка',
  transporter_document_is_order: 'Заявка (П)',
  is_files_cashiers: 'ТТН/CMR',
  transporter_date_order: 'Заявка дата (П)',
  transporter_paid: 'Оплачено',
  transporter_paid_date: 'Дата оплаты',
  transporter_document_invoice_number: '№ счета',
  transporter_document_invoice_date: 'Дата счета',
  transporter_rnn: 'РНН (П)',
  description: 'Комментарий',
  mc_comment: 'Комментарии МЦ',
  inset: '№ вкладки',
  commission: 'Комиссия',
  logistical: 'Логист',
  responsible_salesman: 'Отв. менеджер',
  cargo_type: 'Груз',
  cargo_cost: 'Стоимость, грн',
  seller_weaving_amount: 'Сотка пр.',
  seller_weaving_name: 'ФИО Продажника',
  logistic_weaving_amount: 'Сотка логиста',
  logistic_weaving_name: 'ФИО логиста',
  brokerage_name: 'ФИО Брокера',
  brokerage: 'Брокерские',
  department: 'Направление',
  speedometer_start: 'Выезд',
  speedometer_end: 'Заезд',
  fuel_remaining: 'Остаток',
  fin_monitoring: 'Фин. мониторинг',
  route: 'Маршрут',
  leave_loading_place_date: 'Время выезда с загрузки',
  mc_comments: 'Коментарии МЦ',
  bonus_debt: 'Долг бонус',
  bonus_last_date_paid: 'Дата выплаты бонуса',
  bonus_paid: 'Оплачен бонус',
  bonus_sum: 'Сумма бонуса'
}
