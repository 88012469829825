export default {
	CASH: {
		name: 'Нал',
		fields: [
			'date_loading',
			'point_loadings',
			'point_unloading',
			'transporter_driver_name',
			'transporter_driver_phone',
			'transporter_numbers',
			'transporter_cash_true',
			'transporter_payment_condition',
			'responsible_salesman'
		]
	},
	NO_ORDERS: {
		name: 'Нет заявок',
		fields: [
			'id',
			'date_loading',
			'client_name',
			'client_cash_true_payment_form',
			'client_cash_false_payment_form',
			'client_document_is_order',
			'transporter_driver_name',
			'responsible_salesman'
		]
	},
	FREE_TRANSPORT: {
		name: 'Свободный транспорт',
		fields: [
			'transporter_date_unloading',
			'point_unloading',
			'transporter_vehicle_types',
			'transporter_max_capacity',
			'transporter_volume',
			'transporter_car_brand',
			'responsible_salesman',
			'logistical'
		]
	},
	SANDORA_TRANSPORT: {
		name: 'Отчёт по транспорту Сандора',
		fields: [
			'id',
			'client_name',
			'client_legal_entity',
			'client_transportation_number',
			'point_loadings',
			'point_unloading',
			'date_loading',
			'transporter_driver_name',
			'transporter_driver_phone',
			'transporter_date_unloading'
		]
	},
	FIN_DIRECTOR: {
		name: 'Контроль дебиторки',
		fields: [
			'id',
			'date_loading',
			'point_loadings',
			'point_unloading',
			'client_name',
			'client_code',
			'client_legal_entity',
			'client_document_invoice_number',
			'client_document_invoice_date',
			'client_rnn_blocked',
			'client_debt',
			'client_expiration_date'
		]
	},
	TRANSPORT_DEPARTMENT: {
		name: 'Транспортный',
		fields: [
			'date_loading',
			'point_loadings',
			'point_unloading',
			'client_name',
			'client_cash_true',
			'client_cash_false',
			'transporter_driver_name',
			'transporter_volume',
			'transporter_numbers',
			'transporter_date_unloading',
			'cargo_type',
			'speedometer_start',
			'speedometer_end',
			'fuel_remaining'
		]
	},
	/**
   * Schema for export debtors info to excel
   */
	DEBTORS: {
		client: {
			name: 'Должники (заказчики)',
			fields: [
				'client_code',
				'client_name',
				'client_debt',
				'client_debt_0',
				'client_debt_30',
				'client_debt_31_90',
				'client_debt_91',
				'client_expiration'
			]
		},
		transporter: {
			name: 'Должники (перевозчики)',
			fields: [
				'transporter_code',
				'transporter_name',
				'transporter_debt',
				'transporter_debt_0',
				'transporter_debt_30',
				'transporter_debt_31_90',
				'transporter_debt_91',
				'transporter_expiration'
			]
		},
		responsible: {
			name: 'Должники (бонус)',
			fields: [
				'id',
				'responsible_salesman',
				'bonus_debt',
				'bonus_sum',
				'bonus_paid',
				'bonus_last_date_paid'
			]
		}
	}

}
