export default () => ({
	transportationType: null,
	liveSearch: '',
	loadingRange: null,
	docsTrackFrom: null,
	docsTrackTo: null,
	paidRange: null,
	ttnTrackFrom: null,
	ttnTrackTo: null,
	users: [],
	sellers: [],
	selectedUsers: [],
	selectedSeller: null,
	selectedClient: null,
	department: 2,
	byFields: [],
	expirationRange: null,
	driverID: null,
	routerQuery: {},
	'checkbox-filter': {
		clientDebt: null,
		expiration: null,
		expiration_transporter: null,
		transporterDebt: null,
		clientPaid: null,
		transporterPaid: null,
		transporterPartlyPaid: null,
		docSend: null,
		docSendFalse: null,
		docGetFalse: null,
		ttn: null,
		ttnFalse: null,
		rnnFalse: null,
		noApplications: null,
		notBills: null,
		allMyClientsDeals: null,
		client_litigation: null,
		transporter_ligation: null
	},
	additionalFilterQuery: {}
})
