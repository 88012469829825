import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
    deleteDeal(id) {
        return axios.delete(`${HOST_URL}/v${API_VERSION}/deal/${id}`)
    },
    getDealById(dealId, payload = {}) {
        return axios.get(`${HOST_URL}/v${API_VERSION}/deal/${dealId}`, {
            params: payload
        })
    },
    getBonusByDealId(dealId, payload = {}) {
        return axios.get(`${HOST_URL}/deal/bonus/${dealId}`, {
            params: payload
        })
    },
    getDealByIdForInvoice(dealId, type = null, payload = {}) {
        return axios.get(`${HOST_URL}/deal/transactions-for-invoice/${dealId}/${type}`, {
            params: payload
        })
    },
    getBaseDeal(dealId, payload = {}) {
        return axios.get(`${HOST_URL}/deal/${dealId}`, {
            params: payload
        })
    },
    editDealById(payload) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal`, payload)
    },
    createDeal(payload = {}, headers = {}) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal`, payload, {
            headers: headers
        })
    },
    updateDeal(payload = {}, headers = {}) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal`, payload, {
            headers: headers
        })
    },
    getDeals(payload = {}) {
        return axios.get(`${HOST_URL}/v${API_VERSION}/deal`, {
            params: payload
        })
    },
    updateDealChoosen(payload) {
        return axios.post(`${HOST_URL}/deal/batch-editing`, payload)
    },
    updateDealStatus(payload = {}) {
        return axios.post(`${HOST_URL}/deal/update-status`, payload)
    },

    /**
     * Deal document turnover
     */
    dealDocumentTurnover(payload = {}) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal-document-turnover`, payload)
    },
    uploadBlockedRnnFile(payload = {}) {
        return axios.post(`${HOST_URL}/deal/block-rnn-by-file`, payload, {
            'Content-Type': 'multipart/form-data'
        })
    },
    storeDealDocumentTurnover(payload = {}) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal-document-turnover`, payload)
    },

    storeTransportationData(payload = {}) {
        return axios.post(`${HOST_URL}/v${API_VERSION}/deal-transport-info`, payload)
    },

    getDealDepartments(payload = {}) {
        return axios.get(`${HOST_URL}/v${API_VERSION}/deal-department`, {
            params: payload
        })
    },
    getMarineLines(payload = {}) {
        return axios.get(`${HOST_URL}/v${API_VERSION}/deal-marine-line`, {
            params: payload
        })
    },
    makeReplicate(payload = {}) {
        return axios.post(`${HOST_URL}/deal/replicate`, payload)
    },
    getHelperData(payload = {}) {
        return axios.get(`${HOST_URL}/data-global`, payload)
    },
    getSeparatedVehicleFromDeal(dealID) {
        return axios.get(`${HOST_URL}/deal/separated-vehicles/${dealID}`)
    },
    getHistory(id) {
        return axios.get(`${HOST_URL}/logger/deal/${id}`)
    },

    downloadDriverFiles(id) {
        return axios.get(`${HOST_URL}/zip-file/deal/driver`, {
            params: {
                id
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-type': 'application/zip'
            }
        })
    },
    downloadDocsForInvoice(id) {
        return axios.get(`${HOST_URL}/zip-file/deal/invoice-docs`, {
            params: {
                id
            },
            responseType: 'arraybuffer',
            headers: {
                'Content-type': 'application/zip'
            }
        })
    },
    getGeneralEditingInfo(id) {
        return axios.get(`${HOST_URL}/deal-general-editing/${id}`)
    },
    putCommonEditingData(id, payload = {}) {
        return axios.put(`${HOST_URL}/set-deal-general-editing/${id}`, payload, {
            '_method': 'PUT'
        })
    }
}
